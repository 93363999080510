<template>
  <div :class="$style.field">
    <label
      :class="[$style.field__label, required && $style.field__label_required]"
    >
      {{ label }}
    </label>
    <div :class="$style.field__body">
      <slot :invalid="isInvalid" />
    </div>
    <div v-if="isInvalid" :class="$style.field__error">
      <template v-if="typeof error === 'string'">
        {{ $t(error, { field: label }) }}
      </template>
      <template v-else-if="Array.isArray(error)">
        <div v-for="message in error" :key="message">
          {{ $t(message, { field: label }) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  label: null,
  error: null,
  required: { type: Boolean }
})

const isInvalid = computed<boolean>(() => !!__props.error?.length)
</script>

<style lang="scss" module>
.field {
  &__label {
    display: block;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &_required:after {
      color: var(--danger-color);
      content: '*';
    }
  }

  &__error {
    margin-top: 4px;
    color: var(--danger-color);
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
